// Generated by CoffeeScript 1.10.0
(function() {
  (function($, d, w) {
    var PostRating;
    PostRating = (function() {
      var self;

      self = false;

      function PostRating() {
        self = this;
      }

      PostRating.prototype.isRunnable = function() {
        return true;
      };

      PostRating.prototype.ready = function() {
        self = this;
        self.ajaxUrl = ajax_base_url;
      };

      PostRating.prototype.load = function() {};

      PostRating.prototype.clearMessages = function() {
        return w.setTimeout(function() {
          $('#modal-popup-rating').modal('hide');
          $('#global-messages').remove();
        }, 4000);
      };

      PostRating.prototype.setMessage = function(message) {
        var msgContainer;
        msgContainer = $('<div/>', {
          id: 'global-messages'
        });
        $('<span/>', {
          text: message
        }).appendTo(msgContainer);
        $('#modal-popup-rating .modal-dialog').prepend(msgContainer);
        return $('#modal-popup-rating').modal('show');
      };

      PostRating.prototype.ratePost = function(userId, postId, rate) {
        var dataSend;
        self.showLoading();
        dataSend = {
          'user_id': userId,
          'post_id': postId,
          'level': rate,
          'action': 'save_post_rating',
          'is_ajax': 1
        };
        $('#global-messages').remove();
        $('.share-preview .custom-toggle').removeClass('active');
        $.ajax({
          type: 'POST',
          url: self.ajaxUrl,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            self.setMessage(data.message);
            self.clearMessages();
            $('html,body').animate({
              scrollTop: 0
            }, 'slow');
            self.hideLoading();
          }
        });
      };

      PostRating.prototype.showLoading = function() {
        $('body.single-ricetta .loading').prependTo($('.wrap.container[role="document"]'));
        return $('body.single-ricetta .loading').show();
      };

      PostRating.prototype.hideLoading = function() {
        return $('body.single-ricetta .loading').hide();
      };

      return PostRating;

    })();
    w.postRating = new PostRating();
    postRating.ready();
  })(jQuery, document, window);

}).call(this);
